<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-sm-6">
                <b-form>
                    <b-form-group
                            id="input-group-dorm"
                            label="宿舍別"
                            label-for="input-dorm"
                            description="一個沒有必要不要隨意亂改的東西"
                    >
                        <b-form-input
                                id="input-dorm"
                                v-model="row_data.dorm"
                                type="text"
                                required
                                placeholder="一宿、二宿、忠孝樓"
                                trim
                        />
                    </b-form-group>

                    <b-form-group
                            id="input-group-floor"
                            label="樓層"
                            label-for="input-floor"
                            description="一個沒有必要不要隨意亂改的東西"
                    >
                        <b-form-input
                                id="input-floor"
                                v-model="row_data.floor"
                                required
                                placeholder="1"
                                type="number"
                                min="1"
                                step="1"
                        />
                    </b-form-group>

                    <b-form-group
                            id="input-group-name"
                            label="房間名"
                            label-for="input-name"
                            description="一個沒有必要不要隨意亂改的東西"
                    >
                        <b-form-input
                                id="input-name"
                                v-model="row_data.name"
                                required
                        />
                    </b-form-group>

                    <b-form-group
                            id="input-group-sex"
                            label="性別："
                            description="一個沒有必要不要隨意亂改的東西"
                    >
                        <b-form-radio-group>
                            <b-form-radio v-model="row_data.sex" name="sex-radios" @select="this.row_data.sex=null">未定</b-form-radio>
                            <b-form-radio v-model="row_data.sex" name="sex-radios" value="男">男</b-form-radio>
                            <b-form-radio v-model="row_data.sex" name="sex-radios" value="女">女</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>

                    <b-form-group
                            id="input-group-usable"
                            label="可供使用："
                            label-for="input-usable"
                            description="會影響其他查詢房間的功能"
                    >
                        <b-form-checkbox v-model="row_data.usable" id="input-usable" switch>
                            房間正常可供使用
                        </b-form-checkbox>
                    </b-form-group>

                    <b-form-group
                            id="input-group-tags"
                            label="房間標籤："
                            label-for="input-tags"
                    >
                        <b-form-tags input-id="tags-basic" v-model="row_data.tags" placeholder="想貼啥標籤自己打（按Enter貼上）" class="mb-2"/>
                    </b-form-group>


                    <b-form-group
                            id="input-group-remark"
                            label="備註："
                            label-for="input-name"
                            description="可以做搜尋哦"
                    >
                        <b-textarea
                                id="input-remark"
                                v-model="row_data.remark"
                                required
                        />
                    </b-form-group>
                </b-form>
                <b-button block variant="primary" :disabled="saving" @click="upDataRoom">儲存房間資料</b-button>
            </div>
            <div class="col-sm">
                <div class="row">
                    <div class="col-lg-6" v-for="bed in row_data.beds" v-bind:key="bed.id">
                        <b-card>
                            <b-card-title>{{bed.name}}</b-card-title>
                            <b-card-sub-title>{{row_data.name4human}}</b-card-sub-title>
                            <b-card-text>

                            </b-card-text>

                            <b-card-text v-if="bed.user !== null">使用者：<router-link :to="{name: 'userManagementDetails', params: {uuid: bed.user.uuid }}">{{bed.user.name}}</router-link></b-card-text>
                            <b-card-text v-else>目前此床位無人等待或入住</b-card-text>
                            <b-button class="mx-2" @click="switchBed(bed.id)">換床</b-button>
                            <b-button class="mx-2" v-bind:disabled="bed.user === null" @click="checkOut(bed.user.bedHistory_id, bed.user.name)">離宿</b-button>
                            <!--<b-button class="mx-2" v-bind:disabled="bed.user !== null" @click="bed.available = !bed.available" :id="`disable-bed-btn-${bed.id}`">{{bed.available? '停用': '啟用'}}床位</b-button>
                            <b-button class="mx-2" variant="danger">刪除床位</b-button>-->
                        </b-card>
                    </div>

                </div>
            </div>
        </div>
        <b-modal ref="change-bed-modal" @hidden="reFlash()" hide-footer title="與其他床交換">
            <div class="d-block text-center">
                <h3>換房囉～</h3>
                <p>不管對面是不是空床，總之將兩張床換過來就對了</p>
            </div>
            <!--b-input-group>
            這個是因應改版，在永久刪除前註解的區塊
                <template v-slot:append>
                    <b-button v-on:click="matchRoom">鎖定</b-button>
                </template>
                <b-form-select
                        :options="['一宿', '二宿', '忠孝樓']"
                        v-model="changeBed.search.dorm"
                        :state="changeBed.search.isFind"
                />
                <b-form-input trim placeholder="寢室" v-model="changeBed.search.room" :state="changeBed.search.isFind"/>
                <b-form-input trim placeholder="床位" v-model="changeBed.search.bed" :state="changeBed.search.isFind"/>
            </b-input-group-->
            <lock-room @locked="lockedRoom" v-on:unlock="unlockRoom"/>
            <div class="d-block text-center"><p v-if="changeBed.search.name !== ''">{{changeBed.search.userName}}在此居住</p></div>
            <div class="d-block text-center">
                <h4>備註</h4>
            </div>
            <b-textarea v-model="changeBed.remark" placeholder="這裡可以輸入一些備註" trim/>
            <b-button class="mt-3" variant="primary" block v-bind:disabled="changeBed.target === ''" @click="switchBedConfirm">換！<template v-if="row_data.sex !== changeBed.search.sex && changeBed.search.sex !== ''">即使床位性別不同也是！</template></b-button>
        </b-modal>
    </div>
</template>

<script>
    import axios from "axios";
    import lockRoom from "@/components/lockRoom";

    export default {
        name: "roomDetails",
        data: function () {
            return {
                row_data: {
                    "beds": [],
                    "builder": "",
                    "dorm": "",
                    "floor": null,
                    "id": null,
                    "name": "",
                    "remark": "",
                    "sex": "",
                    "tags": [],
                    "usable": true,
                    "name4human": ""
                },
                changeBed: {
                    show: false,
                    target: '',
                    thisBed: '',
                    remark: '',
                    search:{
                        dorm: '',
                        name: '',
                        bed: '',
                        isFind: false,
                        userName: '',
                        sex: ''
                    }
                },
                saving: false
            }
        },
        watch: {
            '$route' (){
                this.reFlash();
            }
        },
        mounted: function (){
            this.reFlash();
        },
        methods:{
            reFlash: function () {
                axios.get('/api/room/detail',{
                    params: {
                        room_id: this.$route.params.room_id
                    }
                }).then((response)=>{
                    this.row_data = response.data;
                }).catch((error)=>{
                    if(error.response.status === 404){
                        window.alert('找不到該房間，回到前一頁');
                        this.$router.push({name: 'roomManagement'}).catch(()=>{});
                    }else if(error.response.status === 401) {
                        window.alert('你沒有使用這個功能的權限。');
                        this.$router.push({name: 'roomManagement'}).catch(()=>{});
                    }else if(error.response.status === 403){
                        this.$store.dispatch('verifyStatus');
                    }else {
                        window.alert("系統出錯了QQ");
                        window.console.log(error.message);
                        this.$router.push({name: 'roomManagement'}).catch(()=>{});
                    }
                });
                this.changeBed={
                    show: false,
                    target: '',
                    thisBed: '',
                    remark: '',
                    search:{
                        dorm: '',
                        name: '',
                        bed: '',
                        isFind: false,
                        userName: '',
                        sex: ''
                    }
                }
            },
            switchBed: function (thisBed) {
                this.changeBed.thisBed = thisBed;
                this.$refs['change-bed-modal'].show()
            },
            switchBedConfirm: function (){
                axios.post('/api/room/switch_bed',{
                    target: [this.changeBed.target, this.changeBed.thisBed],
                    remark: this.changeBed.remark
                }).then((response) => {
                    window.alert(response.data.msg);
                    this.$refs['change-bed-modal'].hide();
                }).catch((error)=>{
                    if(error.response.status === 400){
                        window.alert(error.response.data.msg)
                    }else {
                        window.alert("發生了什麼，詳見控制台");
                        this.$refs['change-bed-modal'].hide();
                        window.console.log(error.methods);
                    }
                })
            },
            lockedRoom(response){
                //this.modalShow = true; 不知道幹嘛用的，註解也沒有怪事發生
                this.changeBed.target = response.id;
                this.changeBed.search.userName = (response.now_bh !== null)? response.now_bh.user_name: "";
                this.changeBed.search.sex = response.sex;
                this.changeBed.search.isFind = true;
                //axios.get('/api/room/str2bed', {
                //這個是因應改版，在永久刪除前註解的區塊
                //    params: {
                //        dorm: this.changeBed.search.dorm,
                //        room: this.changeBed.search.room,
                //        bed: this.changeBed.search.bed
                //    }
                //}).then((resp)=>{
                //    this.changeBed.target = resp.data.id;
                //    this.changeBed.search.userName = (resp.data.now_bh !== null)? resp.data.now_bh.user_name: "";
                //    this.changeBed.search.sex = resp.data.sex;
                //    this.changeBed.search.isFind = true;
                //}).catch((err) => {
                //    if(err.response){
                //        if(err.response.status === 404){
                //            this.changeBed.target = "";
                //            this.changeBed.search.isFind = false;
                //            this.changeBed.search.userName = '';
                //            this.changeBed.search.sex = '';
                //            window.alert('找不到該床位');
                //        }
                //    }else {
                //        window.alert("出現網路問題，無法連線後端。");
                //        window.console.log(err);
                //    }
                //    this.$refs['change-bed-modal'].hide();
                //})
            },
            unlockRoom(){
                this.changeBed.target = '';
                this.changeBed.search.userName = "";
                this.changeBed.search.sex = '';
                this.changeBed.search.isFind = false;
            },
            checkOut(bedHistory_id, name){
                let data = new FormData();
                data.append("target", bedHistory_id);
                if (window.confirm("你確定你要讓" + name + "離宿嗎？")){
                    axios.post('/api/room/checkOut', data)
                        .then((response)=>{
                            window.alert(response.data.msg);
                            this.reFlash();
                        }).catch((error)=>{
                        window.alert("好像哪裡出問題了");
                        window.console.log(error.message);
                    })
                }
            },
            upDataRoom: function () {
                this.saving = true;
                axios.post('/api/room/detail?room_id=' + this.row_data.id, this.row_data)
                .then((response)=>{
                    window.alert(response.data.msg);
                    this.saving = false;
                    this.reFlash();
                }).catch((error)=>{
                    window.alert(error.message);
                    this.saving = false;
                    this.reFlash();
                })
            }
        },
        components: {
            'lockRoom': lockRoom
        }
    }
</script>

<style scoped>

</style>